import React from 'react';
import classnames from 'classnames';
import LoadingIndicator from './../../../LoadingIndicator';
import FoundationItem from './components/foundationItem';
import GridItem from './components/gridItem';
import Pagination from './../../../Pagination';

export default function Grid({post_type, filter_column, loading, results, setPageNumber, count, pageNumber, pages}){
    return (
        <div
            className={classnames('w-full md:max-w-full-72', {
                'ml-auto': filter_column,
                'mx-auto': !filter_column
            })}
        >
            {loading ?
                <LoadingIndicator />
            : (results && results.length) ?
                <div>
                    {post_type != 'personnel' &&
                        <h3 className="type-preset-6 font-body text-teal-600 leading-none border-b border-gray-150 pb-2" dangerouslySetInnerHTML={{__html: `Showing ${count} result${(count > 1) ? 's' : ''}`}} />
                    }
                    <div className={`${post_type}-grid grid gap-4 lg:gap-8 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-4`}>
                        {post_type == 'foundation' ? results.map((post, index) => {
                            return (
                                <FoundationItem key={post.ID} post={post} index={index} />
                            )
                        }) : results.map((post, index) => {
                            return (
                                <GridItem key={post.ID} post={post} index={index} />
                            )
                        })}
                    </div>
                    <Pagination
                        onPageChange={(pageNum) => {
                            setPageNumber(pageNum);
                        }}
                        totalCount={count}
                        currentPage={(pageNumber == 0) ? 1 : pageNumber}
                        maxPages={pages}
                    />
                </div>
            :
                <div>
                    <p className="text-gray-600 pb-4 mb-4 border-b border-gray-200">
                        No Results
                    </p>
                </div>
            }
        </div>
    )
}
