import React from 'react';

export default function HeaderLogo({
    logo
}){
    return (
        <div className="header-logo flex justify-between relative items-center flex-shrink-0">
            <a className="brand block" href="/" dangerouslySetInnerHTML={{__html: logo}} />
        </div>
    )
}
