import React from 'react';

export default function RowItem({post}){
    return (
        <a className={`resource-${post.ID} group border-gray-150 flex flex-col sm:flex-row items-center md:items-start lg:flex-row py-10`} href={post.url} target={post.target} data-aos="fade-in" data-aos-delay='300'>
            <div className="flex justify-center w-full min-h-51 rounded-md sm:max-w-1/2 lg:max-w-84 overflow-hidden">
                <figure className="w-full relative before:block before:pt-[66.6%] before:bg-beige-100 before:absolute before:w-full before:rounded-md before:top-1/2 before:left-0 before:transform before:-translate-y-1/2 overflow-hidden rounded-md  group-hover:scale-115 transition-all duration-300 descendant-img:min-w-full descendant-img:h-auto" dangerouslySetInnerHTML={{__html: (post.attachment && 'img' in post.attachment ? post.attachment.img : '')}} />
            </div>
            <div className="flex flex-wrap w-full max-w-full sm:max-w-1/2 lg:max-w-full sm:px-4 lg:px-8 py-3 lg:py-0">
                <div className="flex-auto w-full font-body font-bold mb-2 type-preset-7 text-teal-500">
                    {post.resourceType &&
                        <span className='uppercase' dangerouslySetInnerHTML={{__html: post.resourceType}} />
                    }
                    {post.meta_data.display_date && false &&
                        <>
                            <span>
                                &nbsp; &middot; &nbsp;
                            </span>
                            <span dangerouslySetInnerHTML={{__html: post.date}} />
                        </>
                    }
                </div>
                <h3 className="font-body type-preset-4 font-bold text-teal-600 group-hover:text-teal-500 mb-2 relative duration-300  before:transition-transform before:duration-300 before:block before:absolute before:left-0 before:-bottom-1 before:bg-teal-500 before:h-[2px] before:w-full before:scale-x-0 before:origin-right group-hover:before:scale-x-100 group-hover:before:origin-left" dangerouslySetInnerHTML={{__html: post.title}} />
                <p className="flex-auto w-full font-body type-preset-6 text-gray-200 hidden md:block" dangerouslySetInnerHTML={{__html: post.excerpt}} />
                <div className="flex items-center justify-start flex-wrap">
                    {Object.entries(post.taxonomies).filter(([taxonomy, terms]) => {return taxonomy == 'category'}).map(([taxonomy,terms]) => (
                            terms.map((term,i,terms) => (
                                <span key={`${taxonomy}_${i}`} className="flex items-center justify-start font-body mt-2 type-preset-7 text-teal-600 flex-nowrap">
                                    <span dangerouslySetInnerHTML={{__html: term.name}} />
                                    {terms.length > i + 1 &&
                                        <span>
                                            &nbsp; &middot; &nbsp;
                                        </span>
                                    }
                                </span>
                            ))
                        )
                    )}
                </div>
            </div>
        </a>
    )
}
