import React from 'react';
import { Transition } from '@headlessui/react';
import classnames from 'classnames';
import SearchForm from './components/SearchForm/';

export default function SearchBar({
    searchOpen,
    toggleSearch
}){
    return (
        <div id="searchBar" className="fixed z-30 left-0 w-full top-[62px] h-screen-62px sm:top-14 sm:h-screen-14 lg:top-22 lg:h-screen-22">
            <div className={classnames('absolute z-20 inset-0 overflow-hidden w-full transition-colors duration-500 bg-black/0', {
                'bg-black/25': searchOpen
            })}>
                <div className="search-bar-wrapper">
                    <Transition
                        show={searchOpen}
                        enter="transform transition ease-in-out duration-500"
                        enterFrom="-translate-y-full"
                        enterTo="translate-y-0"
                        leave="transform transition ease-in-out duration-500"
                        leaveFrom="translate-y-0"
                        leaveTo="-translate-y-full"
                        className="absolute right-0 left-0 w-full search-bar-inner h-auto"
                    >
                        <div className="search-bar-inner-wrapper pt-8 pb-12 lg:pt-16 lg:pb-20 bg-white w-full h-auto">
                            <div className="max-w-screen-lg mx-auto relative w-full h-full px-8 lg:px-0">
                                <SearchForm searchOpen={searchOpen} toggleSearch={toggleSearch} />
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
    )
}
