import React, { useEffect, useState, useRef } from 'react'
import _, {debounce} from 'lodash';
import classnames from 'classnames';

export default function ImageGrid ({...props}){
    const [gridOne, setGridOne] = useState(props.gridOneImages);
    const [gridTwo, setGridTwo] = useState(props.gridTwoImages);
    const [gridThree, setGridThree] = useState(props.gridThreeImages);
    const [gridFour, setGridFour] = useState(props.gridFourImages);
    const [gridFive, setGridFive] = useState(props.gridFiveImages);
    const [isPaused, setIsPaused] = useState(false);
    const [triggerOffset, setTriggerOffset] = useState(200);
    const [screen, setScreen] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });
    const imgDelay = 2000;
    const [gridCount, setGridCount] = useState(screen.x > 768 ? 5 : 3);
    const [cycleInterval, setCycleInterval] = useState(gridCount * imgDelay);

    const imgGrid = useRef(null);

    const nextImage = (grid) => {
        return grid.paused ? grid.activeImage : (grid.images.length > grid.activeImage + 1 ? grid.activeImage + 1 : 0);
    };

    useEffect(() => {
        const windowResize = () => {
            setScreen(currentDimensions => ({
                ...currentDimensions,
                x: window.innerWidth,
                y: window.innerHeight
            }));
        };
        const windowResized = debounce(windowResize, 500);
        window.addEventListener('resize', windowResized);

        return () => window.removeEventListener('resize', windowResized);
    }, []);

    useEffect(() => {
        if(screen.x < 768){
            setGridCount(count => (3));
            setTriggerOffset(offset => (Math.floor(screen.y - 1 - imgGrid.current.getBoundingClientRect().top  - window.pageYOffset)));
        } else {
            setGridCount(count => (5));
            setTriggerOffset(offset => (Math.floor(screen.y - 1 - imgGrid.current.getBoundingClientRect().top  - window.pageYOffset)));
        }
    }, [screen]);

    useEffect(() => {
        window.AOS.refresh();
    }, [triggerOffset]);

    useEffect(() => {
        setCycleInterval(currentInterval => (gridCount * imgDelay));
    }, [gridCount]);

    useEffect(() => {
        const interval = setInterval((function imageCycle(){
            cycleImages();
            return imageCycle;
        })(), cycleInterval);

        return () => clearInterval(interval);
    }, [cycleInterval]);

    const cycleImages = () => {
        setTimeout(() => {
            setGridOne(currentGridState => ({
                ...currentGridState,
                activeImage: nextImage(currentGridState)
            }));
        },((screen.x < 768) ? 1 : gridOne.cycleOrder) * imgDelay);
        setTimeout(() => {
            setGridTwo(currentGridState => ({
                ...currentGridState,
                activeImage: nextImage(currentGridState)
            }));
        },gridTwo.cycleOrder * imgDelay);
        setTimeout(() => {
            setGridThree(currentGridState => ({
                ...currentGridState,
                activeImage: nextImage(currentGridState)
            }));
        },((screen.x < 768) ? 3 : gridThree.cycleOrder) * imgDelay);
        setTimeout(() => {
            setGridFour(currentGridState => ({
                ...currentGridState,
                activeImage: nextImage(currentGridState)
            }));
        },((screen.x < 768) ? 2 : gridFour.cycleOrder) * imgDelay);
        setTimeout(() => {
            setGridFive(currentGridState => ({
                ...currentGridState,
                activeImage: nextImage(currentGridState)
            }));
        },gridFive.cycleOrder * imgDelay);
    };

    useEffect(() => {
        setGridOne(currentGridState => ({
            ...currentGridState,
            paused: currentGridState.caption ? 1 : isPaused,
            caption: (isPaused && currentGridState.caption) ? 1 : (currentGridState.caption ? 1 : 0),
        }));
        setGridTwo(currentGridState => ({
            ...currentGridState,
            paused: currentGridState.caption ? 1 : isPaused,
            caption: (isPaused && currentGridState.caption) ? 1 : (currentGridState.caption ? 1 : 0),
        }));
        setGridThree(currentGridState => ({
            ...currentGridState,
            paused: currentGridState.caption ? 1 : isPaused,
            caption: (isPaused && currentGridState.caption) ? 1 : (currentGridState.caption ? 1 : 0),
        }));
        setGridFour(currentGridState => ({
            ...currentGridState,
            paused: currentGridState.caption ? 1 : isPaused,
            caption: (isPaused && currentGridState.caption) ? 1 : (currentGridState.caption ? 1 : 0),
        }));
        setGridFive(currentGridState => ({
            ...currentGridState,
            paused: currentGridState.caption ? 1 : isPaused,
            caption: (isPaused && currentGridState.caption) ? 1 : (currentGridState.caption ? 1 : 0),
        }));
    }, [isPaused]);

    const togglePause = () => {
        setIsPaused(paused => (!paused));
    };

    return (
        <div ref={imgGrid} className="flex flex-row justify-between items-stretch relative pt-8 overflow-hidden">
            <button className="absolute top-0 right-0 p-2 flex items-center justify-center leading-none font-body font-bold type-preset-7 text-teal-600 hover:text-teal-500 group transition" onClick={() => {
                togglePause();
            }} data-aos-delay={1200} data-aos-offset={triggerOffset} data-aos-anchor=".row-image-grid" data-aos-anchor-placement="top-bottom" data-aos="fade-left" data-aos-mirror="false" data-aos-once="true">
                <span className="flex items-center justify-center w-5 h-3">
                    {isPaused ?
                        <svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
                            <path d="m15 12.3-6 5v-10z" fill="currentColor" fillRule="evenodd"/>
                        </svg>
                    :
                        <svg width="9" height="9.38" viewBox="0 0 9 12" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="currentColor" strokeWidth="3" fill="none" fillRule="evenodd" strokeLinecap="square">
                                <path d="M1.5 2v8M7.5 2v8"/>
                            </g>
                        </svg>
                    }
                </span>
                <span className="font-bold mr-1 group-hover:underline">{isPaused ? 'RESUME' : 'PAUSE'}</span>
            </button>
            <GridItem
                gridState={gridOne}
                setGridState={setGridOne}
                isPaused={isPaused}
                offset={triggerOffset}
                index={0}
                classes="grid-item-0 block aspect-[1/1] md:aspect-[196/279] basis-[62.25165562913907%] md:basis-[24.968152866242038%]"
            />
            <div className="block md:flex-none basis-[1.5330624999999997%] md:w-[10px]"></div>
            <GridItem
                gridState={gridTwo}
                setGridState={setGridTwo}
                isPaused={isPaused}
                offset={triggerOffset}
                index={1}
                classes="grid-item-1 hidden md:block aspect-[196/279] basis-[24.968152866242038%]"
            />
            <div className="hidden md:block flex-none w-[10px]"></div>
            <div className="grid-item-wrapper overflow-hidden relative grow shrink basis-[37.748344370860926%] md:basis-[16.560509554140126%]">
                <GridItem
                    gridState={gridThree}
                    setGridState={setGridThree}
                    isPaused={isPaused}
                    offset={triggerOffset}
                    index={2}
                    classes="grid-item-2 block absolute top-0 aspect-[285/229] md:aspect-[130/137] w-full"
                />
                <div className="hidden flex-none h-[12px] md:h-[10px]"></div>
                <GridItem
                    gridState={gridFour}
                    setGridState={setGridFour}
                    isPaused={isPaused}
                    offset={triggerOffset}
                    index={3}
                    classes="grid-item-3 block absolute bottom-0 top-auto md:top-[10px] aspect-[285/229] md:aspect-[130/137] w-full"
                />
            </div>
            <div className="hidden md:block flex-none w-[10px]"></div>
            <GridItem
                gridState={gridFive}
                setGridState={setGridFive}
                isPaused={isPaused}
                offset={triggerOffset}
                index={4}
                classes="grid-item-4 hidden md:block aspect-[263/279] basis-[33.503184713375794%]"
            />
        </div>
    )
}

function GridItem({gridState,setGridState,isPaused,index,classes,offset}){
    return (
        <div className={`group md:relative grow shrink grid-item ${classes}`} data-cycle-order={gridState.cycleOrder} data-aos-delay={gridState.delay} data-aos-offset={offset} data-aos-anchor=".row-image-grid" data-aos-anchor-placement="top-bottom" data-aos="fade-in" data-aos-mirror="false" data-aos-once="true">
            <div className="absolute inset-0 md:bg-gray-800">
                {gridState.images.map((imgObj, i) => (
                    <ImgObject key={`${index}-${i}`} imgIndex={i} imgObj={imgObj} gridObject={gridState} setGridState={setGridState} isPaused={isPaused} />
                ))}
            </div>
        </div>
    )
}

function ImgObject({imgIndex,imgObj,gridObject,setGridState,isPaused}){
    return (
        <>
            <div
                className={classnames('absolute inset-0 before:z-20 transition-all before:transition-all before:duration-300 before:bg-black before:absolute before:inset-0 before:z-20 bg-cover bg-center', {
                    'before:opacity-0': (!gridObject.caption),
                    'before:opacity-50': (gridObject.caption),
                    'opacity-0 duration-500 delay-0 md:delay-250 pointer-events-none ease-out': (gridObject.activeImage != imgIndex),
                    'opacity-100 duration-700 delay-0 ease-in': (gridObject.activeImage == imgIndex),
                })}
                style={{backgroundImage: `url(${imgObj.src})`}}
                role="img"
                aria-label={imgObj.caption}
            />
        </>
    )
}
