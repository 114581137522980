import React from 'react';
import { Transition } from '@headlessui/react';

// import Accordion from '../../../Accordion';
// import NavItem from './components/NavItem';
import MobileNavItem from './components/MobileNavItem';

export default function MobileNav({
    menuOpen,
    toggleMenu,
    primaryNav,
    topLevel = false,
    onClick
}){
    return (
        <div id="mobileMenu" className="fixed z-30 lg:hidden left-0 w-full top-[62px] h-screen-62px sm:top-14 sm:h-screen-14">
            <div className="absolute z-20 inset-0 overflow-hidden w-full">
                <div className="mobile-nav-wrapper">
                    <Transition
                        show={menuOpen}
                        enter="transform transition ease-in-out duration-700"
                        enterFrom="-translate-y-full"
                        enterTo="translate-y-0"
                        leave="transform transition ease-in-out duration-700"
                        leaveFrom="translate-y-0"
                        leaveTo="-translate-y-full"
                        className="h-auto fixed right-0 left-0 flex w-screen bg-white top-[62px] max-h-screen-62px sm:top-14 sm:max-h-screen-14 overflow-auto"
                    >
                        <nav className="mobile-nav-primary relative w-screen h-screen--20 box-content mb-20 pb-20">
                            <div className="mobile-menu-primary">
                                <ul id="mobile-menu-1" className="mobile-menu-primary nav relative flex flex-col py-8">
                                    {primaryNav.map((item, idx) => {
                                        return (
                                            <MobileNavItem key={idx} item={item} topLevel={topLevel} onClick={id => onClick(id)} />
                                        )
                                    })}
                                </ul>
                            </div>
                        </nav>
                    </Transition>
                </div>
            </div>
        </div>
    )
}
