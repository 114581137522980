import React from 'react';
import RowItem from './components/rowItem';

export default function TeaserList({results}){
    return (
        <>
            {(results && results.length) ?
                <>
                    {results.map((post, index) => {
                        return (
                            <RowItem key={post.ID} post={post} index={index} />
                        )
                    })}
                </>
            :
                <div className="text-teal-600 border-y border-gray-150 py-10">
                    <p className="type-preset-6 leading-none m-0 text-center">
                        No Results
                    </p>
                </div>
            }
        </>
    )
}
