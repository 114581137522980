import React, { useState } from 'react'
import axios from 'axios'

export default function ContactForm ({post_id=false, confirmation_message=""})
{
  const [submitStatus, setSubmitStatus] = useState(0);

  function handleSubmit(event) {
    event.preventDefault();

    var payload = {
      "post_id": post_id,
      "data": processPayload(event.target)
    }

    axios.post('/wp/wp-admin/admin-ajax.php?action=contact-form', payload)
      .then(res => {
        setSubmitStatus(submitStatus => 1);
      }).catch(error => {
        setSubmitStatus(submitStatus => 2);
      })
  }

  function processPayload(form) {
    var obj = {};
    var elements = form.querySelectorAll("input, select, textarea");
    var labels = form.querySelectorAll("h5");
    for (var i = 0; i < elements.length; ++i) {

      var element = elements[i];
      var name = element.name;
      var value = element.value;

      if(labels[i]) {
        var label = labels[i].innerHTML;
      } else {
        var label = "";
      }

      if(name) {
        obj[name] = {
          "label": label,
          "value": value
        };
      }
    }

    return obj;
  }

  return (<>
    { submitStatus == 1 && <h2 className="font-display text-center type-preset-3 py-6">{confirmation_message}</h2> }
    { submitStatus != 1 &&
        <form className="flex flex-wrap" onSubmit={handleSubmit}>
            <label className="w-full md:w-1/2 mb-6 md:pr-4" htmlFor="name">
                <h5 className="type-preset-6 font-bold text-teal-600 mb-2 pl-2">Name</h5>
                <input required="required" className="w-full border border-gray-150 rounded text-teal-600 focus:ring-teal-500 focus:border-teal-500" type="text" name="name" />
            </label>
            <label className="w-full md:w-1/2 mb-6 md:pl-4" htmlFor="company_name">
                <h5 className="type-preset-6 font-bold text-teal-600 mb-2 pl-2">Company Name</h5>
                <input required="required" className="w-full border border-gray-150 rounded text-teal-600 focus:ring-teal-500 focus:border-teal-500" type="text" name="company_name" />
            </label>
            <label className="w-full md:w-1/2 mb-6 md:pr-4" htmlFor="email">
                <h5 className="type-preset-6 font-bold text-teal-600 mb-2 pl-2">E-mail</h5>
                <input required="required"className="w-full border border-gray-150 rounded text-teal-600 focus:ring-teal-500 focus:border-teal-500" type="email" name="email" />
            </label>
            <label className="w-full md:w-1/2 mb-6 md:pl-4" htmlFor="hear_about_us">
                <h5 className="type-preset-6 font-bold text-teal-600 mb-2 pl-2">How did you hear about us? (Optional)</h5>
                <input className="w-full border border-gray-150 rounded text-teal-600 focus:ring-teal-500 focus:border-teal-500" type="text" name="hear_about_us" />
            </label>
            <label className="w-full mb-6" htmlFor="message">
                <h5 className="type-preset-6 font-bold text-teal-600 mb-2 pl-2">How can we help you?</h5>
                <textarea required="required" className="w-full border border-gray-150 rounded text-teal-600 h-28 focus:ring-teal-500 focus:border-teal-500" type="text" name="message"></textarea>
            </label>
            {/*spam protection*/}
            <input className="hidden" type="text" name="detect" tabIndex="-1" />
            <input className="btn btn--teal rounded-full type-preset-6 font-body font-normal mx-auto" type="submit" name="submit" value="Get in Touch" />
        </form>
    }
  </>)
}
