require('lodash')
require('alpinejs')

import AOS from 'aos';
window.AOS = AOS;
AOS.init({
    duration: 300,
    easing: 'ease-in-out',
    delay: 0,
    once: true
});

import Splide from '@splidejs/splide';
document.addEventListener('DOMContentLoaded', () => {
    let sliders = document.querySelectorAll('.splide');
    sliders.forEach(slider => {
        let config = JSON.parse(slider.getAttribute('data-splide'));
        let splide;
        if(config.pagination){
            splide = new Splide(slider);
            splide.on('pagination:mounted', function(data){
                data.list.classList.add( 'splide__pagination--navbar' );
                data.items.forEach(function(item){
                    item.button.textContent = config.pagination.pages[item.page];
                });
            });
            splide.mount();
        } else {
            splide = new Splide(slider).mount();
        }
        let customPaginationElements = slider.querySelectorAll("[data-splide-pagination-go-to]");
        if (customPaginationElements.length) {
            const startSlide = splide.Components.Controller.getIndex();
            customPaginationElements.forEach(function(item, idx) {
                item.addEventListener("click", () => {
                    splide.Components.Controller.go(Number(item.getAttribute('data-splide-pagination-go-to')));
                });
                const inactiveClasses = item.getAttribute('data-splide-pagination-inactive-classes');
                if (idx !== startSlide && inactiveClasses) {
                    item.classList.add(...inactiveClasses.split(" "));
                }
            });
            splide.on('move', function(data){
                customPaginationElements.forEach(function(item, index) {
                    const activeClasses = item.getAttribute('data-splide-pagination-active-classes');
                    if (activeClasses) {
                        if (data === index) {
                            item.classList.add(...activeClasses.split(" "));
                        } else {
                            item.classList.remove(...activeClasses.split(" "));
                        }
                    }
                    const inactiveClasses = item.getAttribute('data-splide-pagination-inactive-classes');
                    if (inactiveClasses) {
                        if (data === index) {
                            item.classList.remove(...inactiveClasses.split(" "));
                        } else {
                            item.classList.add(...inactiveClasses.split(" "));
                        }
                    }
                });
            });
            const activeClasses = customPaginationElements[startSlide].getAttribute('data-splide-pagination-active-classes');
            if (activeClasses) {
                customPaginationElements[startSlide].classList.add(...activeClasses.split(" "));
            }
        }
    });
});

import ReactComponentLoader from './classes/ReactComponentLoader'
ReactComponentLoader({
  'archive': require('./components/Archive/').default,
  'teaserList': require('./components/Archive/components/Results/teaserList').default,
  'mainMenu': require('./components/MainMenu/').default,
  'mainSearch': require('./components/MainSearch/').default,
  'imgGrid': require('./components/ImageGrid/').default,
  'personnelCarousel': require('./components/PersonnelCarousel/').default,
  'contactForm': require('./components/ContactForm/').default
})
