import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

export default function ReactComponentLoader (components)
{
    document
        .querySelectorAll('[data-react-component]')
        .forEach((elm) => {
            if (!components[elm.dataset.reactComponent]) return
            let ReactComponent = components[elm.dataset.reactComponent]
            let props = elm.dataset.reactProps ? JSON.parse(elm.dataset.reactProps) : {}
            if(elm.dataset.reactUseHistory == 1){
                ReactDOM.render(
                    <BrowserRouter>
                        <ReactComponent {...props} />
                    </BrowserRouter>, elm
                );
            } else {
                ReactDOM.render(
                    <ReactComponent {...props} />, elm
                );
            }
        });
}
