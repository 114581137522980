import React, { useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { XIcon } from '@heroicons/react/outline';

export default function SearchForm({
    searchOpen,
    toggleSearch
}){
    const formEl = useRef(null);

    return (
        <>
            <Formik
                initialValues={{ s: '' }}
                onSubmit={(values, { setSubmitting }) => {
                    // formEl.current.submit();
                    window.location.href = `/search/${values.s}`;
                    setSubmitting(false);
                }}
                validate={ values => {
                    const errors = {};

                    if (!values.s) {
                      errors.s = "Please enter a search term.";
                    }

                    return errors;
                }} >
                {({ handleSubmit }) => (
                <form
                    action="/"
                    method="get"
                    ref={formEl}
                    onSubmit={handleSubmit}
                    className="site-search"
                >
                    <div>
                        <div className="flex flex-row items-center justify-between mb-8 lg:mb-4">
                            <label htmlFor="searchInput" className="type-preset-6 text-gray-200 font-body font-normal">Search for Grant Opportunities, Resources, News, and more</label>
                            <button
                                type="button"
                                onClick={toggleSearch}
                                className="bg-gray-100 hover:bg-teal-600 text-teal-600 hover:text-white flex justify-center items-center rounded-full font-bold h-10 w-10 lg:h-12 lg:w-12 cursor-pointer transition-all duration-500 self-auto mb-0 flex-none"
                                >
                                    <XIcon className="block h-4 w-4" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="flex items-center flex-row pb-1 lg:pb-2 border-b border-solid border-gray-100">
                            <div className="relative flex-grow w-auto mb-0 pl-6 lg:pl-12">
                                <Field
                                    type="search"
                                    name="s"
                                    id="searchInput"
                                    className="py-2 px-2 lg:py-3 lg:px-4 w-full type-preset-5 lg:type-preset-4 bg-white text-teal-600 placeholder-teal-600 font-display border-none focus:outline-none active:outline-none focus:outline-none focus:ring-1 focus:ring-gray-100 focus:ring-offset-0 focus:ring-offset-gray-100"
                                    placeholder="Search"
                                    autoComplete="off"
                                />
                                <span className="flex items-center pl-0 lg:pl-2 absolute left-0 top-1/2 transform -translate-y-1/2 text-teal-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 lg:h-8 lg:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </span>
                            </div>
                            <button
                                type="submit"
                                id="search-button"
                                className="bg-teal-400 hover:bg-teal-600 text-white hover:text-white flex justify-center items-center h-10 w-10 lg:h-12 lg:w-12 cursor-pointer transition-all duration-500 ml-0 lg:ml-4 flex-none"
                            >
                                <span className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" fill="#fff" viewBox="0 0 24 24" stroke="#fff">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                    <ErrorMessage name="s" component="div" className="text-red-700 mt-2 type-preset-7" />
                </form>
                )}
            </Formik>
        </>
    )
}
