import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { XIcon, SearchIcon } from '@heroicons/react/outline';
import _ from 'lodash'
import classnames from 'classnames';
import { searchQuery } from '../../classes/API'
import Pagination from './../Pagination';
import LoadingIndicator from './../LoadingIndicator';

export default function mainSearch ({searchTerm='', contentTypes=[], filters=[]}){
    const [results, setResults] = useState([]);
    const [count, setCount] = useState(0);
    const [pages, setPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState({});
    const [currentSearchTerm, setCurrentSearchTerm] = useState(searchTerm);
    const [loading, setLoading] = useState(false);
    const [hasSubmitCompleted, setHasSubmitCompleted] = useState(false);
    const [filterState, setFilterState] = useState({});

    const history = useHistory();

    const searchTermFromPath = (path = window.location.pathname) => {
        let searchTermTmp = searchTerm;
        if(path.split('/').length == 3){
            searchTermTmp = path.split('/')[2];
        }
        if(searchTermTmp != currentSearchTerm){
            setCurrentSearchTerm(searchTermTmp);
        }
        return searchTermTmp;
    };

    const queryFromURL = (url = window.location.hash) => {
        let queryString = url.split("?").length > 1 ? url.split("?")[1] : null;
        let queryArgs = {};
        if(queryString){
            let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
            for (var i = 0; i < pairs.length; i++) {
                var pair = pairs[i].split('=');
                queryArgs[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
            }
        } else {
            if(contentTypes.length){
                queryArgs['type'] = contentTypes.join(',');
            }
        }
        if((query['page'] && pageNumber != query['page']) || pageNumber > 1){
            queryArgs['page'] = pageNumber;
        }

        queryArgs['s'] = searchTermFromPath();

        return queryArgs;
    };

    const urlFromQuery = (args = query) => {
        let queryString = '';
        _.each(args, (option, key) => {
            if((key == 'type' && option == contentTypes.join(',')) || key == 's'){
                return;
            }
            queryString += `${key}=${option}&`;
        });
        return queryString.length ? `#?${queryString.slice(0, -1)}` : '';
    };

    useEffect(() => {
        setLoading(true);
        let args = queryFromURL();
        fetchQuery(args);
        let filterStates = Object.assign({}, ...filters.map((item,idx) => {
          return { [item.name]: ((item.name in args) ? true : false) }
        }));
        setFilterState(filterStates);
        setQuery(args);
    }, []);

    useEffect(() => {
        if(pageNumber > 0){
            let args = queryFromURL();
            if(args != query){
                setQuery(args);
            }
        }
    },[pageNumber]);

    useEffect(() => {
        let queryHash = urlFromQuery();
        if(queryHash != window.location.hash){
            history.push({hash: queryHash});
        }
        if(('s' in query && query.s) && query.s != currentSearchTerm){
            setCurrentSearchTerm(query.s);
        }
    },[query]);

    useEffect(() => {
      return history.listen((location) => {
         let args = queryFromURL(location.hash);
         if(args != query){
            setQuery(args);
         }
         let setPage;
         if('page' in args){
            setPage = parseInt(args['page']);
         } else {
            setPage = 1;
         }
         setPageNumber(setPage);

         window.scrollTo({
             top: (document.querySelector('[data-react-component=mainSearch]').getBoundingClientRect().top + window.pageYOffset - 150),
             behavior: 'smooth'
         });
         setLoading(true);
         let queryArgs = queryFromURL();
         fetchQuery(queryArgs);
      })
    },[history])

    async function fetchQuery(args){
        // args.defaultResourceTypes = contentTypes;
        args.s = decodeURI(searchTermFromPath());
        let response = await searchQuery(args);
        let _results = await response.json();

        setResults(_results.results);
        setCount(_results.count);
        setPages(_results.max_pages);
        setLoading(false);
    }

    let initialFormikState = {
        s: currentSearchTerm
    };
    filters.map((filter) => {
        initialFormikState[filter.name] = !(filter.name in query) ? [] : (_.isArray(query[filter.name]) ? query[filter.name] : query[filter.name].split(','));
        if(query['type'] == null || query['type'] == undefined){
            initialFormikState['type'] = contentTypes;
        }
    });

    const toggleFilterState = (name) => {
        let filterStates = {};
        for(var filterName in filterState){
            if(filterName == name){
                filterStates[filterName] = !filterState[filterName];
            } else {
                filterStates[filterName] = filterState[filterName];
            }
        }
        setFilterState(filterStates);
    };

    const handleSearch = () => {
        let searchTerm = document.getElementById('searchField').value;
        window.location.href = `/search/${searchTerm}`;
    }

    return (
        <div>
            <Formik
                initialValues={initialFormikState}
                enableReinitialize={true}
                onSubmit={(values) => {
                    let queryValues = _.pickBy(values, value => value.length > 0);
                    setPageNumber(0);
                    setQuery(queryValues);
                }}
                onReset={() => {
                    setPageNumber(0);
                    setQuery({});
                }}
            >
                {({handleSubmit, values, setFieldValue, handleReset}) => (
                    <Form onChange={() => {
                        // handleSubmit();
                    }}>
                        {/*<SearchBar hasSubmitCompleted={hasSubmitCompleted} term={searchTerm} handleSearch={handleSearch} handleReset={handleReset} setFieldValue={setFieldValue} setPageNumber={setPageNumber} />*/}
                        <div className="container mx-auto py-24">
                            <div className="flex space-x-16">
                                {(filters && filters.length > 0) &&
                                    <Filters filters={filters} handleSubmit={handleSubmit} handleReset={handleReset} setPageNumber={setPageNumber} filterState={filterState} toggleFilterState={toggleFilterState} />
                                }
                                <Results filters={filters} loading={loading} results={results} setPageNumber={setPageNumber} count={count} pageNumber={pageNumber}  pages={pages} />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

function SearchBar({hasSubmitCompleted, term, handleSearch, handleReset, setFieldValue, setPageNumber}){
    return (
        <div>
            <div className="search-box bg-gray-900 text-gray-200 pt-8 pb-8 px-15 lg:px-0">
                <div className="max-w-lg m-auto flex flex-col justify-between lg:h-auto">
                    <div>
                        <div className="flex flex-col lg:flex-row items-center justify-between mb-8 lg:mb-4">
                            <label htmlFor="searchInput" className="type-preset-5 lg:type-preset-4 text-gray-200 font-bold font-display order-2 lg:order-1">What are you looking for?</label>
                        </div>
                        <div className="flex items-center flex-col lg:flex-row">
                            <div className="relative flex-grow w-full lg:w-auto mb-4 lg:mb-0">
                                <Field
                                    type="search"
                                    name="s"
                                    id="searchField"
                                    className="py-3 px-4 w-full type-preset-6 bg-gray-200 text-gray-900 shadow-xl"
                                    placeholder="Search"
                                    autoComplete="off"
                                    onKeyDown={(e) =>{
                                        if(e.key == 'Enter'){
                                            handleSearch();
                                        }
                                    }}
                                />
                            </div>
                            <button
                                type="submit"
                                className="bg-gray-200 hover:bg-gray-900 text-gray-900 hover:text-gray-200 flex justify-between items-center rounded-full font-bold h-auto w-auto py-3 lg:py-2 px-10 lg:px-4 cursor-pointer transition-all duration-500 font-bold type-preset-6 leading-tight ml-4"
                                onClick={() => {
                                    handleSearch();
                                }}
                            >
                                <span>Submit</span>
                                <span className="flex items-center pl-2 hidden">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                    <ErrorMessage name="s" component="div" className="text-orange-400 mt-2 type-preset-7" />
                </div>
            </div>
        </div>
    )
}

function Filters({filters, handleSubmit, handleReset, setPageNumber, filterState, toggleFilterState}){
    return (
        <div className="w-1/4">
            {filters.map((filter) => {
                return (
                    <div key={filter.name} className="mb-8">
                        <button type="button" aria-controls={`filter-group_${filter.name}`} aria-expanded={filterState[filter.name]} aria-label="Toggle filter group" className="flex justify-between items-center py-2 pl-4 bg-gray-200 hover:bg-gray-300 transition-all duration-300 w-full" onClick={() => {
                                toggleFilterState(filter.name);
                            }
                        }>
                            <h4 className="type-preset-7 font-body font-bold leading-none text-gray-900">{filter.label}</h4>
                            <span className={`flex justify-center items-center flex-none w-8 h-8 px-2 py-2 mx-2 ${(filterState[filter.name]) ? 'rotate-180' : ''}`}>
                                <svg viewBox="0 0 1024 574"><path d="M1015 564q-10 10-23 10t-23-10L512 82 55 564q-10 10-23 10T9 564q-9-10-9-24t9-24L489 10q10-10 23-10t23 10l480 506q9 10 9 24t-9 24z"></path></svg>
                            </span>
                        </button>
                        <ul id={`filter-group_${filter.name}`} className={`bg-gray-100 h-auto first:mt-4 last:mb-4 overflow-auto transition-all duration-300 ${(filterState[filter.name]) ? 'max-h-56' : 'max-h-0'}`}>
                            {Object.entries(filter.options).map(([value, label]) => {
                                return (
                                    <li key={`${filter.name}_${value}`} className="py-2 px-4 first:mt-2 last:mb-2 bg-gray-100 hover:bg-gray-200 transition-all duration-300">
                                        <label className="type-preset-7 font-body font-bold text-gray-900 cursor-pointer flex justify-start items-center">
                                          <Field className="form-checkbox h-5 w-5 text-gray-500 hover:text-gray-900 border-2 border-gray-500 hover:border-gray-900 bg-gray-50 hover:bg-gray-200 mr-2 transition-all duration-300" type="checkbox" name={filter.name} value={value} />
                                          <span>{label}</span>
                                        </label>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                )
            })}
            <div className="my-4 flex justify-between items-center">
                <button type="button" className="btn btn-sm font-body type-preset-7 px-8 transition-all duration-300" onClick={
                    () => {
                        handleSubmit();
                    }
                }>Filter Results</button>
                <button type="button" className="btn btn-sm font-body type-preset-7 bg-gray-200 text-black px-8 transition-all duration-300" onClick={
                    () => {
                        handleReset({});
                    }
                }>Reset</button>
            </div>
        </div>
    )
}

function Results({filters, loading, results, setPageNumber, count, pageNumber, pages}){
    return (
        <div
            className={classnames('mx-auto', {
                'w-full lg:max-w-screen-md': (!filters || filters.length == 0),
                'w-3/4': (filters && filters.length > 0)
            })}
        >
            {loading ?
                <LoadingIndicator />
            : (results && results.length) ?
                <div>
                    <h3 className="type-preset-6 font-body text-teal-600 pb-2 border-b border-gray-100" dangerouslySetInnerHTML={{__html: `Showing ${count} result${(count > 1) ? 's' : ''}`}} />
                    <div className="-mb-4">
                        {results.map((resource) => {
                            return (
                                <article key={resource.ID} className="first:mt-4 my-8 py-4 relative group before:absolute before:-bottom-4 before:left-0 before:w-full before:h-px before:bg-gray-100">
                                    <div className="uppercase text-teal-600 font-body font-bold type-preset-7" dangerouslySetInnerHTML={{__html: resource.resourceType}}></div>
                                    <a className="block" href={resource.url} target={resource.target}>
                                        <h2 className="type-preset-5 leading-none font-body font-bold text-teal-500 inline-block mb-1 relative before:transition-all before:duration-300 before:origin-left before:block before:absolute before:left-0 before:-bottom-1 before:bg-teal-500 before:h-[2px] before:w-0/1 group-hover:before:w-full" dangerouslySetInnerHTML={{__html: resource.title}}></h2>
                                    </a>
                                    <div className="type-preset-6 font-body text-gray-200" dangerouslySetInnerHTML={{__html: resource.excerpt}}></div>
                                </article>
                            )
                        })}
                    </div>
                    <div className="-mb-8">
                        <Pagination
                            onPageChange={(pageNum) => {
                                setPageNumber(pageNum);
                            }}
                            totalCount={count}
                            currentPage={(pageNumber == 0) ? 1 : pageNumber}
                            maxPages={pages}
                        />
                    </div>
                </div>
            :
                <div>
                    <p className="text-gray-600 pb-4 mb-4 border-b border-gray-200">
                        No Results
                    </p>
                </div>
            }
        </div>
    )
}
