import React, { useState } from 'react';
import classnames from 'classnames';

import MobileSubNav from '../MobileSubNav';

export default function MobileNavItem({
    item,
    topLevel,
    isSubItem,
    onClick
}){
    let subItems = ('children' in item && item.children.length) ? item.children : (('subchildren' in item  && item.subchildren.length) ? item.subchildren : []);

    let isActive = item.active;
    let hasActiveChild = ('activeChild' in item && item.activeChild);
    let hasChildren = ('children' in item && item.children.length) || ('subchildren' in item && item.subchildren.length);

    return (
        <li
            className={classnames('menu-item', {
                'border-b border-gray-100 last:border-b-0': topLevel,
                'current-menu-item': isActive,
                'current-menu-ancestor': hasActiveChild,
                'menu-item-has-children': hasChildren
            })}
        >
            {(topLevel && item.children.length) ?
                <button
                    className={`flex items-center justify-between px-8 py-6 text-teal-600 w-full type-preset-7 font-bold uppercase tracking-wide uppercase leading-none menu-item-${item.ID}`}
                    onClick={(id) => onClick(item.ID)}
                >
                    <span className="pt-px" dangerouslySetInnerHTML={{__html: item.title}} />
                    <span
                        className={classnames('transform translate-x-1 origin-center transition-all duration-500', {
                            '-rotate-90': (!item.active),
                            'rotate-0': (item.active)
                        })}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 block" fill="none" viewBox="0 0 32 32" stroke="currentColor">
                            <path d="M24 11.305l-7.997 11.39L8 11.305z" fill="currentColor"></path>
                        </svg>
                    </span>
                </button>
            :
                <a
                    className={classnames(`flex items-center justify-between text-teal-600 type-preset-7 menu-item-${item.ID}`, {
                            'px-8 py-6 font-bold uppercase tracking-wide uppercase leading-5': topLevel,
                            'px-8 py-2': !topLevel,
                            'px-8 py-2': (!topLevel && isSubItem),
                            'font-bold': item.active
                    })
                } href={item.url}>
                    <span dangerouslySetInnerHTML={{__html: item.title}} />
                </a>
            }
            {(subItems.length > 0) ?
                <MobileSubNav items={subItems} open={item.active} id={item.ID} />
            :
                null
            }
        </li>
    )
}
