import React, { useEffect, useRef, useState } from 'react'
import LoadingIndicator from './../LoadingIndicator';
import { useInterval } from './../../hooks/useInterval';

export default function PersonnelCarousel({...props}){
    const [personnel, setPersonnel] = useState(props.headshots);
    const [pageNumber, setPageNumber] = useState(0);
    const [count, setCount] = useState(props.count);

    const [firstPersonnelItem, setFirstPersonnelItem] = useState({
        currentIndex: 0,
        nextIndex: 3,
    });
    const firstImage = useRef(null);

    const [secondPersonnelItem, setSecondPersonnelItem] = useState({
        currentIndex: 1,
        nextIndex: 4,
    });
    const secondImage = useRef(null);

    const [thirdPersonnelItem, setThirdPersonnelItem] = useState({
        currentIndex: 2,
        nextIndex: 5,
    });
    const thirdImage = useRef(null);

    useEffect(() => {
        const interval = setInterval((function indexCycle(){
            cycleIndex();
            return indexCycle;
        })(), 4500);

        return () => clearInterval(interval);
    }, []);

    const cycleIndex = () => {
        setPageNumber(_pageNumber => (_pageNumber + 1));
    };

    const nextIndex = (index) => {
        return (index + 3) % count;
    };

    useEffect(() => {
        if(pageNumber < 1){
            return;
        }
        setTimeout(() => {
            let node = firstImage.current;
            let children = node.children;
            let insert = children[1].cloneNode(true);
            node.insertBefore(insert,children[0]);
            insert.classList.add('reveal');
            setTimeout(function(){
                insert.classList.remove('reveal');
            },100);
            setTimeout(function(){
                setFirstPersonnelItem(_firstPersonnelItem => ({
                    currentIndex: _firstPersonnelItem.nextIndex,
                    nextIndex: nextIndex(_firstPersonnelItem.nextIndex),
                }));
                setTimeout(() => {
                    firstImage.current.children[0].remove();
                }, 1500);
            },1500);
        }, 1500);

        setTimeout(() => {
            let node = secondImage.current;
            let children = node.children;
            let insert = children[1].cloneNode(true);
            node.insertBefore(insert,children[0]);
            insert.classList.add('reveal');
            setTimeout(function(){
                insert.classList.remove('reveal');
            },100);
            setTimeout(function(){
                setSecondPersonnelItem(_secondPersonnelItem => ({
                    currentIndex: _secondPersonnelItem.nextIndex,
                    nextIndex: nextIndex(_secondPersonnelItem.nextIndex),
                }));
                setTimeout(() => {
                    secondImage.current.children[0].remove();
                }, 1500);
            },1500);
        }, 3000);

        setTimeout(() => {
            let node = thirdImage.current;
            let children = node.children;
            let insert = children[1].cloneNode(true);
            node.insertBefore(insert,children[0]);
            insert.classList.add('reveal');
            setTimeout(function(){
                insert.classList.remove('reveal');
            },100);
            setTimeout(function(){
                setThirdPersonnelItem(_thirdPersonnelItem => ({
                    currentIndex: _thirdPersonnelItem.nextIndex,
                    nextIndex: nextIndex(_thirdPersonnelItem.nextIndex),
                }));
                setTimeout(() => {
                    thirdImage.current.children[0].remove();
                }, 1500);
            },1500);
        }, 4500);
    }, [pageNumber]);

    return (
        <div className="personnel-carousel">
            {(personnel && personnel.length) ?
                <div className="flex flex-row items-center justify-end">
                    <div className="flex flex-col items-end justify-between w-1/2 xl:w-auto">
                        <div className="person person-1 relative w-full lg:w-[185px] before:block before:w-full before:pt-[129%] lg:aspect-[100/129] lg:before:hidden mb-3 rounded-md overflow-hidden" ref={firstImage} data-aos="scale-in" data-aos-offset="-100" data-aos-anchor=".personnel-carousel" data-aos-anchor-placement="top-bottom">
                            <div className="absolute inset-0 -top-1/10 bg-center-1 bg-cover bg-no-repeat bg-orange-200" style={{ backgroundImage: `url(${personnel[firstPersonnelItem.currentIndex].bgImg})` }} />
                            <div className="absolute inset-0 -top-1/10 bg-center-1 bg-cover bg-no-repeat bg-orange-200" style={{ backgroundImage: `url(${personnel[firstPersonnelItem.nextIndex].bgImg})` }} />
                        </div>
                        <div className="person person-3 relative w-full lg:w-[250px] before:block before:w-full before:pt-[100%] lg:aspect-[1/1] lg:before:hidden rounded-md overflow-hidden" ref={thirdImage} data-aos-delay="400" data-aos="scale-in" data-aos-offset="-100" data-aos-anchor=".personnel-carousel" data-aos-anchor-placement="top-bottom">
                            <div className="absolute inset-0 -top-1/10 bg-center-1 bg-cover bg-no-repeat bg-yellow-200" style={{ backgroundImage: `url(${personnel[thirdPersonnelItem.currentIndex].bgImg})` }} />
                            <div className="absolute inset-0 -top-1/10 bg-center-1 bg-cover bg-no-repeat bg-yellow-200" style={{ backgroundImage: `url(${personnel[thirdPersonnelItem.nextIndex].bgImg})` }} />
                        </div>
                    </div>
                    <div className="person person-2 relative w-[296px] before:block before:w-full before:pt-[136%] lg:aspect-[100/136] lg:before:hidden ml-3 rounded-md overflow-hidden" ref={secondImage} data-aos-delay="200" data-aos="scale-in" data-aos-offset="-100" data-aos-anchor=".personnel-carousel" data-aos-anchor-placement="top-bottom">
                        <div className="absolute inset-0 -top-1/10 bg-center-1 bg-cover bg-no-repeat bg-teal-300" style={{ backgroundImage: `url(${personnel[secondPersonnelItem.currentIndex].bgImg})` }} />
                        <div className="absolute inset-0 -top-1/10 bg-center-1 bg-cover bg-no-repeat bg-teal-300" style={{ backgroundImage: `url(${personnel[secondPersonnelItem.nextIndex].bgImg})` }} />
                    </div>
                </div>
            :
                <LoadingIndicator />
            }
        </div>
    )
}
