import React, { Fragment } from 'react';
import classnames from 'classnames';

import SubNav from '../SubNav';

export default function NavItem({
    item,
    topLevel
}){
    let classes = item.active ? 'current-menu-item' : '';
    classes += ('activeChild' in item && item.activeChild) ? ' current-menu-ancestor' : '';
    classes += (('children' in item && item.children.length) || ('subchildren' in item && item.subchildren.length)) ? ' menu-item-has-children' : '';
    let subItems = ('children' in item && item.children.length) ? item.children : (('subchildren' in item  && item.subchildren.length) ? item.subchildren : []);

    return (
        <li className={`menu-item ${classes}`}>
            {(topLevel && item.children.length) ?
                <button className="text-teal-600 block transition-colors duration-300 hover:text-teal-500 font-bold relative">
                    <span className="type-preset-7 tracking-wide block uppercase relative py-[40px] before:absolute before:block before:right-0 before:top-0 before:w-0/1 before:h-[5px] before:bg-teal-400 before:transition-all before:duration-300 before:ease-in-out hover:before:w-full" dangerouslySetInnerHTML={{__html: item.title}} />
                </button>
            :
                <a className="text-teal-600 block transition-colors duration-500 hover:text-teal-500 relative" href={item.url}>
                    <span className={classnames('', {
                        'type-preset-7 tracking-wide font-bold block uppercase relative py-[40px] before:absolute before:block before:right-0 before:top-0 before:w-0/1 before:h-[5px] before:bg-teal-400 before:transition-all before:duration-600 before:ease-in-out hover:before:w-full': topLevel,
                        'type-preset-6': !topLevel,
                    })}
                    dangerouslySetInnerHTML={{__html: item.title}} />
                </a>
            }
            <SubNav items={subItems} />
        </li>
    )
}
