import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../hooks/usePagination';

const Pagination = ({
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    maxPages,
    className
}) => {

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    maxPages
  });
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames('pagination-container justify-center items-center flex my-6 pt-4 type-preset-6 text-teal-600', { [className]: className })}
    >
      <li
        className={classnames('pagination-item font-bold px-2 h-8 w-auto flex items-center justify-center mx-0 transition-all duration-300', {
          'cursor-default opacity-6': currentPage === 1,
          'cursor-pointer': currentPage !== 1,
        })}
        onClick={onPrevious}
      >
        <span className="font-body font-normal inline-block text-teal-600 leading-5 relative before:transition-all before:duration-300 before:origin-right before:block before:absolute before:right-0 before:-bottom-0.5 before:bg-teal-400 before:h-[2px] before:w-full hover:before:w-0/1">Previous</span>
      </li>
      <li
        className={classnames('pagination-item font-bold px-2 h-10 w-10 flex items-center justify-center rounded-full mx-2 text-teal-600', {
          'pointer-events-none cursor-not-allowed opacity-75': currentPage === 1,
          'cursor-pointer': currentPage !== 1,
        })}
        onClick={onPrevious}
      >
        <svg className="h-4 w-4 rotate-180 origin-center" fill="currentColor" stroke="currentColor" viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path d='M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z'/>
        </svg>
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <li key={index} className="pagination-item font-bold dots px-2 mv-2">&#8230;</li>;
        }

        return (
          <li
            className={classnames('pagination-item font-bold px-2 h-10 w-10 flex items-center justify-center rounded-full mx-2 transition-all duration-300', {
              'bg-teal-200 hover:bg-transparent text-teal-600 cursor-default': pageNumber === currentPage,
              'hover:bg-teal-200 text-teal-600 cursor-pointer': pageNumber !== currentPage
            })}
            key={index}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames('pagination-item font-bold px-2 h-10 w-10 flex items-center justify-center rounded-full mx-2 text-teal-600', {
          'pointer-events-none cursor-not-allowed opacity-75': currentPage === lastPage,
          'cursor-pointer': currentPage !== lastPage,
        })}
        onClick={onNext}
      >
        <svg className="h-4 w-4" fill="currentColor" stroke="currentColor" viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path d='M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z'/>
        </svg>
      </li>
      <li
        className={classnames('pagination-item font-bold px-2 h-8 w-auto flex items-center justify-center mx-0 transition-all duration-300', {
          'cursor-default opacity-6': currentPage === lastPage,
          'cursor-pointer': currentPage !== lastPage,
        })}
        onClick={onNext}
      >
        <span className="font-body font-normal inline-block text-teal-600 leading-5 relative before:transition-all before:duration-300 before:origin-right before:block before:absolute before:right-0 before:-bottom-0.5 before:bg-teal-400 before:h-[2px] before:w-full hover:before:w-0/1">Next</span>
      </li>
    </ul>
  );
};

export default Pagination;
