import React, { useEffect, useState, useRef } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import classnames from 'classnames';

import HeaderLogo from './components/HeaderLogo/';
import DesktopNav from './components/DesktopNav/';
import MobileNav from './components/MobileNav/';
import SearchBar from './components/SearchBar/';

export default function MainMenu({
    logo,
    primaryNav
}){
    const [menuOpen, setMenuOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [headerSticky, setHeaderSticky] = useState(false);
    const [navHover, setNavHover] = useState(false);
    const [primaryNavItems, setPrimaryNavItems] = useState([]);

    const siteHeader = useRef(null);
    const siteHeaderInner = useRef(null);
    const menuWrapper = useRef(null);

    const toggleClasses = (el, ...cls) => cls.map(cl => el.classList.toggle(cl));

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        siteHeader.current.classList.toggle('menu-open');
        menuWrapper.current.classList.toggle('menu-open');
        menuWrapper.current.classList.remove('search-open');
        document.body.classList.toggle('no-scroll');
        if(searchOpen){
            setSearchOpen(false);
            siteHeader.current.classList.remove('search-open');
            menuWrapper.current.classList.remove('search-open');
        } else {
            if(window.pageYOffset < 50){
                setTimeout(() => {
                    setHeaderSticky(!headerSticky);
                }, 400);
            }
        }
    };

    const toggleSearch = () => {
        setSearchOpen(!searchOpen);
        siteHeader.current.classList.toggle('search-open');
        menuWrapper.current.classList.toggle('search-open');
        menuWrapper.current.classList.remove('menu-open');
        if(menuOpen){
            setMenuOpen(false);
            siteHeader.current.classList.remove('menu-open');
            menuWrapper.current.classList.remove('menu-open');
        } else {
            if(window.pageYOffset < 50){
                setTimeout(() => {
                    setHeaderSticky(!headerSticky);
                }, 400);
            }
        }
        if(!searchOpen){
            document.addEventListener('click', closeSearch);
        }
    }

    const closeSearch = (e) => {
        let clickElm = e.target;
        if(clickElm.classList.contains('search-bar-inner')){
            setSearchOpen(false);
            siteHeader.current.classList.remove('search-open');
            menuWrapper.current.classList.remove('search-open');
            if(window.pageYOffset < 50){
                setTimeout(() => {
                    setHeaderSticky(false);
                }, 400);
            }
            document.removeEventListener('click', closeSearch);
        }
    }
    // useEffect(() => {
    //     document.addEventListener('click', closeSearch);
    // }, []);


    const searchPage = () => {
        return document.body.classList.contains('search-results');
    }

    useEffect(() => {
        const onScroll = () => {
            if(window.pageYOffset > siteHeader.current.offsetTop){
                if(window.pageYOffset >= 50){
                    setHeaderSticky(true);
                } else {
                    setHeaderSticky(false);
                }
            } else {
                setHeaderSticky(false);
            }
        };
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        const _items = _.map(primaryNav, (item) => {
            if(item.children.length){
                let _item = Object.assign({}, item);
                _item.children = [];
                item.active = item.active || item.activeChild;
                item.children = [_item, ...item.children];
            }
            return item;
        });
        setPrimaryNavItems(_items);
    }, []);

    const updateActiveMenuItem = (id) => {
        const _items = _.map(primaryNav, (item) => {
            if(item.children.length){
                let _item = Object.assign({}, item);
                _item.children = [];
                item.active = item.ID == id ? (item.active ? false : true) : false;
                item.children = _.uniqBy([_item, ...item.children], 'ID');
            }
            return item;
        });
        setPrimaryNavItems(_items);
    }

    return (
        <div
            ref={menuWrapper}
            className={classnames('menu-wrapper', {
                'menu-open': (menuOpen),
                'search-open': (searchOpen),
                'nav-hover': (navHover)
            })}
        >
            <div
                ref={siteHeader}
                id="site-header"
                className={classnames('site-header banner sticky-header flex items-center justify-between', {
                    'sticky-header-bg': (headerSticky || menuOpen || searchOpen),
                    'menu-open': (menuOpen),
                    'search-open': (searchOpen),
                    'nav-hover': (navHover)
                })}
            >
                <div ref={siteHeaderInner} className="site-header-container w-full lg:container mx-auto flex justify-between items-center relative z-20">
                    <HeaderLogo logo={logo} />
                    <div className="flex items-center justify-end space-x-4 -mr-4 lg:mr-0">
                        <DesktopNav primaryNav={primaryNavItems} topLevel={true} />
                        <div className="flex items-center">
                            <button
                                type="button"
                                onClick={toggleSearch}
                                className={classnames('search-toggle z-10 flex justify-center items-center h-[48px] w-[48px] cursor-pointer transition-all duration-300 transform lg:hover:scale-105 text-teal-600 opacity-1 bg-white hover:bg-gray-100', {
                                    'lg:scale-105': (searchOpen || searchPage),
                                    'opacity-0 pointer-events-none lg:opacity-1 lg:pointer-events-auto': menuOpen
                                })}
                                aria-label="Toggle search bar"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 lg:h-4 w-6 lg:w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </button>
                            <button
                                type="button"
                                onClick={toggleMenu}
                                className={classnames('mobile-menu-toggle relative h-[48px] w-[48px] inline-flex lg:hidden items-center justify-center cursor-pointer transition-colors duration-500 text-teal-600 hover:text-teal-600', {
                                    'text-teal-600 hover:text-teal-600': (menuOpen || headerSticky || searchOpen)
                                })}
                                aria-label="Open nav menu"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className={classnames('fill-current absolute left-0 top-0 transition-opacity', {
                                    'opacity-1': menuOpen,
                                    'opacity-0': !menuOpen
                                })} width="48px" height="48px" viewBox="0 0 48 48">
                                    <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <path d="M29.7885822,16.1464466 L31.9099026,18.267767 L25.8994466,24.2784466 L31.9099026,30.2885822 L29.7885822,32.4099026 L23.7784466,26.3994466 L17.767767,32.4099026 L15.6464466,30.2885822 L21.6574466,24.2784466 L15.6464466,18.267767 L17.767767,16.1464466 L23.7784466,22.1574466 L29.7885822,16.1464466 Z" fill="currentColor"></path>
                                    </g>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" className={classnames('fill-current absolute left-0 top-0 transition-opacity', {
                                    'opacity-1': !menuOpen,
                                    'opacity-0': menuOpen
                                })} width="48px" height="48px" viewBox="0 0 48 48">
                                    <g stroke="currentColor" strokeWidth="1" fillRule="evenodd">
                                        <path d="M14,18 L34,18 L34,20 L14,20 L14,18 Z M14,23 L34,23 L34,25 L14,25 L14,23 Z M14,28 L34,28 L34,30 L14,30 L14,28 Z" fill="currentColor"></path>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <MobileNav menuOpen={menuOpen} toggleMenu={toggleMenu} primaryNav={primaryNavItems} topLevel={true} onClick={id => updateActiveMenuItem(id)} />
            <SearchBar searchOpen={searchOpen} toggleSearch={toggleSearch} />
        </div>
    )
}
