import React, { useEffect, useRef } from 'react'

export default function GridItem({post,index}){
    let thumbnailBgClassname = (post.meta_data && ('thumbnail_background_color' in post.meta_data)) ? post.meta_data.thumbnail_background_color : 'bg-transparent';
    let thumbnailAspectRatio = (post.postType == 'personnel') ? 'before:pt-[130%] lg:aspect-[10/13]' : 'before:pt-[100%] lg:aspect-[1/1]';
    const figure =  useRef(null);
    // const toggleCover = () => {
    //     let imgEl = figure.current.children[0];
    //     if(imgEl){
    //         let src = imgEl.getAttribute('src');
    //         figure.current.style.backgroundImage = `url("${src}")`;
    //         imgEl.style.display = 'none';
    //     }
    // };
    // useEffect(() => {
    //     toggleCover();
    // }, []);
    return (
        <a href={post.url} target={post.target} title={post.title} className="block group" data-aos="fade-in" data-aos-duration="300" data-aos-delay={(index+1) * 200} data-aos-once="true" data-aos-offset={index > 3 ? 0 : -225} data-aos-anchor={`.${post.postType}-grid`} data-aos-anchor-placement="top-center">
            <figure
                ref={figure}
                className={`w-full relative before:block before:w-full ${thumbnailAspectRatio} lg:before:hidden overflow-hidden group-hover:scale-105 transition-all duration-300 shadow-none hover:shadow-xl rounded-md transition descendant-img:max-w-none descendant-img:w-auto descendant-img:min-h-full descendant-img:max-h-full  ${thumbnailBgClassname} bg-cover bg-center bg-no-repeat`}
                dangerouslySetInnerHTML={{__html: (post.attachment && 'img' in post.attachment ? post.attachment.img : '')}}
            />
            <div className="px-2 py-4">
                <h4 className="type-preset-6 font-bold text-teal-600 leading-none">{post.title}</h4>
                <p className="text-gray-200 mt-1 leading-tight">{post.meta_data.role.replace("/","/ ")}</p>
            </div>
        </a>
    )
}
