import React, { useEffect, useState } from 'react'
import _, {debounce} from 'lodash';

export default function Foundation({post,index}){
    const [columnCount, setColumnCount] = useState(window.innerWidth < 768 ? 2 : (window.innerWidth < 1024 ? 3 : 4));
    useEffect(() => {
        const windowResize = () => {
            setColumnCount(window.innerWidth < 768 ? 2 : (window.innerWidth < 1024 ? 3 : 4));
        };
        const windowResized = debounce(windowResize, 500);
        window.addEventListener('resize', windowResized);

        return () => window.removeEventListener('resize', windowResized);
    }, []);

    return (
        <a href={post.url} target={post.target} title={post.title} className="block group" data-aos="fade-in" data-aos-duration="300" data-aos-delay={((index % columnCount) + 1) * ((Math.floor((index+1) / columnCount) * 50) + 150)} data-aos-once="true" data-aos-anchor-placement="top-bottom" data-aos-offset="100">
            <figure className="w-full relative before:block before:w-full before:pt-[60%] overflow-hidden group-hover:opacity-90 group-hover:scale-105 transition-all duration-300 descendant-img:max-w-full-16 border border-gray-100 md:border-transparent hover:border-gray-100 shadow-none hover:shadow-xl rounded-md transition" dangerouslySetInnerHTML={{__html: (post.attachment && 'img' in post.attachment ? post.attachment.img : '')}} />
        </a>
    )
}
