export function restQuery(route = '', params = {})
{
    var url = new URL(window.location.origin + route)
    Object
        .keys(params)
        .forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url);
}
export function searchQuery(params = {})
{
    var url = new URL(window.location.origin + '/wp-json/wp/v2/search')
    Object
        .keys(params)
        .forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url);
}
