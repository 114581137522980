import React from 'react';

import NavItem from './components/NavItem';

export default function DesktopNav({
    primaryNav,
    topLevel = false
}){
    return (
        <nav className="nav-primary hidden lg:flex header-menu items-center">
            <div className="menu-primary">
                <ul id="main-menu-1" className="menu-primary nav relative flex">
                    {primaryNav.map((item, idx) => {
                        return (
                            <NavItem key={idx} item={item} topLevel={topLevel} />
                        )
                    })}
                </ul>
            </div>
        </nav>
    )
}
