import React from 'react'

export default function LoadingIndicator ()
{
  return (
    <div className="relative h-32 w-full">
        <svg style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)'}} xmlns="http://www.w3.org/2000/svg" width={40} height={40} viewBox="0 0 40 40"><path opacity=".2" d="M20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-.001-8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634 6.425 0 11.633 5.209 11.633 11.634 0 6.426-5.208 11.634-11.633 11.634z" /><path d="M26.013 10.047l1.654-2.866a14.855 14.855 0 0 0-7.466-2.012v3.312c2.119 0 4.1.576 5.812 1.566z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite" /></path></svg>
    </div>
  )
}
