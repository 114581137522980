import React from 'react';

import NavItem from '../NavItem';

export default function SubNav({
    items
}){
    return (
        <ul className="sub-menu">
            {items.map((item, idx) => {
                return (
                    <NavItem key={idx} item={item} isSubItem={true} />
                )
            })}
        </ul>
    )
}
