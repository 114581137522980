import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';

import MobileNavItem from '../MobileNavItem';

export default function MobileSubNav({
    items,
    open,
    id
}){
    const subnav = useRef(null);
    const [setHeight, setHeightState] = useState('0px');
    useEffect(() => {
        setHeightState(open === true ? `${subnav.current.scrollHeight}px` : '0px');
    }, [open]);
    return (
        <ul
            ref={subnav}
            className={classnames('sub-menu normal-case font-normal type-preset-7 max-h-0 h-auto transition-all', {
                'overflow-hidden': !open,
                'overflow-auto': open,
            })} style={{maxHeight: `${setHeight}`}}
        >
            {items.map((item, idx) => {
                if('children' in item && item.children.length){
                    return (
                        <li key={idx} className="menu-item last:pb-2">
                            <a className={classnames("pl-8 py-2 block text-teal-600", {
                                            'font-bold': item.active
                                        })} href={item.url} dangerouslySetInnerHTML={{__html: item.title}} />
                            <ul>
                                {item.children.map((subitem, i) =>
                                    <li key={`${idx}_${i}`}>
                                        <a className={classnames("pl-8 py-2 block text-teal-600", {
                                            'font-bold': subitem.active
                                        })} href={subitem.url} dangerouslySetInnerHTML={{__html: subitem.title}} />
                                    </li>
                                )}
                            </ul>
                        </li>
                    )
                }
                return (
                    <MobileNavItem key={idx} item={item} isSubItem={true} />
                )
            })}
        </ul>
    )
}
