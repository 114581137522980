import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import _ from 'lodash'
import classnames from 'classnames';
import { restQuery } from '../../classes/API'
import Rows from './components/Results/rows';
import Grid from './components/Results/grid';

export default function Archive ({post_type='resource', rest_route='/wp-json/wp/v2/resource', layout='rows', filters=[]}){
    const [results, setResults] = useState([]);
    const [count, setCount] = useState(0);
    const [pages, setPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState({});
    const [loading, setLoading] = useState(true);
    const [filterState, setFilterState] = useState({});
    const [filtersOpen, setFiltersOpen] = useState(false);

    const history = useHistory();

    const queryFromURL = (url = window.location.hash) => {
        let queryString = url.split("?").length > 1 ? url.split("?")[1] : null;
        let queryArgs = {};
        if(queryString){
            let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
            for (var i = 0; i < pairs.length; i++) {
                var pair = pairs[i].split('=');
                queryArgs[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
            }
        }
        if((query['page'] && pageNumber != query['page']) || pageNumber > 1){
            queryArgs['page'] = pageNumber;
        }

        return queryArgs;
    };

    const urlFromQuery = (args = query) => {
        let queryString = '';
        _.each(args, (option, key) => {
            queryString += `${key}=${option}&`;
        });
        return queryString.length ? `#?${queryString.slice(0, -1)}` : '';
    };

    useEffect(() => {
        setLoading(true);
        let args = queryFromURL();
        fetchQuery(args);
        let filterStates = Object.assign({}, ...filters.map((item,idx,_filters) => {
          return { [item.name]: item.expanded ? true : (item.name in args && Object.values(_filters[idx]['options']).indexOf(args[item.name]) > 7 ? true : false) }
        }));
        setFilterState(filterStates);
        setFiltersOpen(filtersState => (window.innerWidth > 767));
        setQuery(args);
    }, []);

    useEffect(() => {
        if(pageNumber > 0){
            let args = queryFromURL();
            if(args != query){
                setQuery(args);
            }
        }
    },[pageNumber]);

    useEffect(() => {
        let queryHash = urlFromQuery();
        if(queryHash != window.location.hash){
            history.push({hash: queryHash});
        }
    },[query]);

    useEffect(() => {
      return history.listen((location) => {
         let args = queryFromURL(location.hash);
         if(args != query){
            setQuery(args);
         }
         let setPage;
         if('page' in args){
            setPage = parseInt(args['page']);
         } else {
            setPage = 1;
         }
         setPageNumber(setPage);

         window.scrollTo({
             top: (document.querySelector('[data-react-component=archive]').getBoundingClientRect().top + window.pageYOffset - 150),
             behavior: 'smooth'
         });
         setLoading(true);
         let queryArgs = queryFromURL();
         fetchQuery(queryArgs);
      })
    },[history])

    async function fetchQuery(args){
        let route = rest_route + urlFromQuery();
        let response = await restQuery(route, args);
        let results = await response.json();
        let count = ('count' in results) ? results.count : response.headers.get('X-WP-Total');
        let pages = ('max_pages' in results) ? results.max_pages : response.headers.get('X-WP-TotalPages');

        setResults(('results' in results) ? results.results : results);
        setCount(count);
        setPages(pages);
        setLoading(false);
    }

    let initialFormikState = {};
    filters.map((filter) => {
        initialFormikState[filter.name] = !(filter.name in query) ? [] : (_.isArray(query[filter.name]) ? query[filter.name] : query[filter.name].split(','));
    });

    const toggleFilterState = (name) => {
        let filterStates = {};
        for(var filterName in filterState){
            if(filterName == name){
                filterStates[filterName] = !filterState[filterName];
            } else {
                filterStates[filterName] = filterState[filterName];
            }
        }
        let menu = document.getElementById(`filter-group_${name}_2`);
        let toggle = document.getElementById(`filter${name}Toggle`);
        let style = (toggle.currentStyle ? toggle.currentStyle.display : getComputedStyle(toggle, null).display) !== 'inline-block' && (!filterState[name]) ? 'max-height:' + menu.scrollHeight + 'px' : '';
        menu.style = style;
        setFilterState(filterStates);
    };

    const toggleFilters = (close = false) => {
        setFiltersOpen(filtersState => ((close) ? false : !filtersState));
        let menu = document.getElementById('filterWrapper');
        let toggle = document.getElementById('filterToggle');
        let style = (toggle.currentStyle ? toggle.currentStyle.display : getComputedStyle(toggle, null).display) !== 'block' && ((close) ? false : !filtersOpen) ? 'max-height:' + menu.scrollHeight + 'px' : '';
        menu.style = style;
    };

    return (
        <div>
            <Formik
                initialValues={initialFormikState}
                enableReinitialize={true}
                onSubmit={(values) => {
                    toggleFilters(true);
                    let queryValues = _.pickBy(values, value => value.length > 0);
                    setPageNumber(0);
                    setQuery(queryValues);
                }}
                onReset={() => {
                    setPageNumber(0);
                    setQuery({});
                }}
            >
                {({handleSubmit, values, setFieldValue, handleReset}) => (
                    <Form onChange={() => {
                        // handleSubmit();
                    }}>
                        <div className="container mx-auto">
                            <div className="flex flex-col md:flex-row">
                                {(filters && filters.length > 0) &&
                                    <Filters filters={filters} handleSubmit={handleSubmit} handleReset={handleReset} setPageNumber={setPageNumber} filterState={filterState} toggleFilterState={toggleFilterState} filtersOpen={filtersOpen} toggleFilters={toggleFilters} />
                                }
                                {(layout == 'grid') ?
                                    <Grid post_type={post_type} filter_column={filters && filters.length > 0} loading={loading} results={results} setPageNumber={setPageNumber} count={count} pageNumber={pageNumber}  pages={pages} />
                                :
                                    <Rows post_type={post_type} filter_column={filters && filters.length > 0} loading={loading} results={results} setPageNumber={setPageNumber} count={count} pageNumber={pageNumber}  pages={pages} />
                                }
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

function Filters({filters, handleSubmit, handleReset, setPageNumber, filterState, toggleFilterState, filtersOpen, toggleFilters}){
    return (
        <div className="md:w-58">
            <button id="filterToggle" type="button" aria-controls="archive-filters" aria-expanded={filtersOpen} aria-label="Toggle filters" className="flex justify-between items-center py-4 mb-8 border-b border-gray-150 w-full md:block md:mb-0 md:py-0 md:border-b-0 text-left" onClick={() => {
                    toggleFilters();
                }
            }>
                <h3 className="type-preset-6 font-body text-teal-600 leading-none">Filter</h3>
                <span
                    className={classnames('md:hidden transform origin-center transition-transform duration-300', {
                        '-rotate-90': (!filtersOpen),
                        'rotate-0': (filtersOpen)
                    })}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 block" fill="none" viewBox="0 0 32 32" stroke="currentColor">
                        <path d="M24 11.305l-7.997 11.39L8 11.305z" fill="currentColor"></path>
                    </svg>
                </span>
            </button>
            <div id="filterWrapper" className="overflow-auto transition-all duration-300 max-h-0 md:max-h-full md:overflow-visible bg-white -mx-1 px-1">
                <div className="flex justify-start items-center md:border-t border-gray-150 md:pt-4 md:mt-2 mb-8">
                    <button type="button" className="btn btn--teal-border rounded-full font-body type-preset-6 text-teal-600 transition-all duration-300" onClick={
                        () => {
                            handleSubmit();
                        }
                    }>Apply</button>
                    <button type="button" className="font-body type-preset-6 text-teal-600 transition-all duration-300 flex justify-between items-center ml-4 space-x-2 group" onClick={
                        () => {
                            handleReset({});
                        }
                    }>
                        <span>
                            <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="m16.96 6.88.175.17-1.414 1.414c-1.983-1.982-5.173-2.007-7.126-.054-1.952 1.953-1.928 5.143.055 7.126 1.93 1.93 5.006 2.004 6.969.204l.156-.15 1.436 1.436c-2.733 2.734-7.2 2.7-9.975-.076-2.776-2.776-2.81-7.242-.077-9.976 2.677-2.677 7.015-2.7 9.8-.094zM19.292 5v6h-6.092l6.092-6z" fill="currentColor" fillRule="nonzero"/>
                            </svg>
                        </span>
                        <span className="inline-block mb-1 leading-none relative before:transition-all before:duration-300 before:block before:absolute before:left-0 before:-bottom-1 before:bg-teal-500 before:h-[2px] before:w-full before:scale-x-100 before:origin-left group-hover:before:scale-x-0 group-hover:before:origin-left">Clear Filters</span>
                    </button>
                </div>
                {filters.map((filter) => {
                    return (
                        <div key={filter.name} className="mb-8">
                            <h4 className="type-preset-7 font-body font-bold leading-none text-teal-600 uppercase tracking-wide">{filter.label}</h4>
                            <ul id={`filter-group_${filter.name}`} className={`h-auto -ml-2 last:mb-4 overflow-hidden transition-all duration-300`}>
                                {Object.entries(filter.options).slice(0,7).map(([label,value]) => {
                                    return (
                                        <li key={`${filter.name}_${value}`} className="py-2 px-2 first:mt-2 last:mb-2">
                                            <label className="type-preset-6 font-body text-teal-600 cursor-pointer flex justify-start items-start">
                                              <Field className="form-checkbox h-5 w-5 text-teal-400 hover:text-teal-600 border-2 border-gray-150 bg-white mr-2 transition-colors duration-300 translate-y-0.5" type="checkbox" name={filter.name} value={value} />
                                              <span dangerouslySetInnerHTML={{__html: `${label}`}} />
                                            </label>
                                        </li>
                                    )
                                })}
                            </ul>
                            {Object.entries(filter.options).length > 7 &&
                                <>
                                    <ul id={`filter-group_${filter.name}_2`} className={classnames("h-auto -ml-2 -mt-4 overflow-visible max-h-full md:overflow-hidden transition-all duration-300", {
                                        'md:max-h-0': !filterState[filter.name]
                                    })}>
                                        {Object.entries(filter.options).slice(7).map(([label,value]) => {
                                            return (
                                                <li key={`${filter.name}_${value}`} className="py-2 px-2 first:mt-2 last:mb-2">
                                                    <label className="type-preset-6 font-body text-teal-600 cursor-pointer flex justify-start items-start">
                                                      <Field className="form-checkbox h-5 w-5 text-teal-400 hover:text-teal-600 border-2 border-gray-150 bg-white mr-2 transition-colors duration-300 translate-y-0.5" type="checkbox" name={filter.name} value={value} />
                                                      <span dangerouslySetInnerHTML={{__html: `${label}`}} />
                                                    </label>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    <button id={`filter${filter.name}Toggle`} type="button" aria-controls={`filter-group_${filter.name}_2`} aria-expanded={filterState[filter.name]} aria-label={`Toggle more ${filter.label} filters`} className={`hidden md:flex justify-start items-center py-2 group ${(filterState[filter.name]) ? 'mt-0' : 'mt-2'}`} onClick={() => {
                                            toggleFilterState(filter.name);
                                        }
                                    }>
                                        <span
                                            className={classnames('hidden md:inline-block mr-2 transform origin-center transition-transform duration-300', {
                                                '-rotate-90': (!filterState[filter.name]),
                                                'rotate-0': (filterState[filter.name])
                                            })}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 block" fill="none" viewBox="0 0 32 32" stroke="currentColor">
                                                <path d="M24 11.305l-7.997 11.39L8 11.305z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <span className="type-preset-6 font-body text-teal-600 inline-block mb-1 leading-none relative before:transition-all before:duration-300 before:block before:absolute before:left-0 before:-bottom-1 before:bg-teal-500 before:h-[2px] before:w-full before:scale-x-100 before:origin-left group-hover:before:scale-x-0 group-hover:before:origin-left">{(!filterState[filter.name]) ? 'See More' : 'See Less'}</span>
                                    </button>
                                </>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


